<template>
  <div class="dashboard" v-if="activeProject.loaded && activeProject && activeProject.project_license">
    <h1 class="console-home__header" v-if="$route.name !== 'pagenotfound'">
      <span>{{ activeProject.name }}</span>
      <div class="badge"
        v-if="!isPackage"
        :class="[(activeProject.project_license.license_key.isEnterprise ||
        activeProject.project_license.license_key.isEnterpriseAnnual) ? 'enterprise' : 'standard' ,
        activeProject.project_license.license_key.isPackage ? 'package' : '']">
        <svg>
          <use v-bind="{ 'xlink:href': '#license-badge'}" />
        </svg><span>{{activeProject.project_license.license_key.key.replace('Annual', '')}}</span>
      </div>
    </h1>
    <div
      class="getting-started"
      v-if="activeProject.show_get_started"
    >
      <h2 class="getting-started__header">Great, you are almost done.</h2>
      <div class="billing-verified-promo"
        v-if="activeProject && activeProject.billing && activeProject.billing.is_verified &&
        activeProject.group !== 'customer_trail_project'">
        <span class="success">
          <svg>
            <use v-bind="{ 'xlink:href': '#tick-icon-white' }" />
          </svg>
        </span>
        <template v-if="keyData && keyData.ufriendly_package_names">
          <p>
            Start accessing
            {{keyData.ufriendly_package_names[0]}} data
          </p>
        </template>
        <template v-else>
          <p>Start accessing live data</p>
        </template>
      </div>
      <div class="free-trail-promo"
        v-if="activeProject && activeProject.group === 'customer_trail_project' && activeProject.free_trail_detail">
        <p>Your Trial ends in {{$filters.duration(activeProject.free_trail_detail.trail_end)}}
          <span v-if="$filters.duration(activeProject.free_trail_detail.trail_end) > 1">
            days
          </span>
          <span v-else>
            day
          </span>
        </p>
      </div>
      <div class="free-trail-promo"
        v-else-if="activeProject && activeProject.group === 'customer_trail_project'
        && !activeProject.free_trail_detail">
        <p>Your Trial got ended!</p>
      </div>
      <div class="getting-started_steps">
        <template v-if="(activeProject && activeProject.billing && !activeProject.billing.is_verified)
         || (activeProject && !activeProject.billing)">
          <div class="step-1">
            <span v-if="!activeProject.project_license ||
            !activeProject.project_license.is_active"
            class="error">
              <svg>
                <use v-bind="{ 'xlink:href': '#arrow-icon' }" />
              </svg>
            </span>
            <span v-else class="success">
              <svg>
                <use v-bind="{ 'xlink:href': '#tick-icon-white' }" />
              </svg>
            </span>
            <p>Successfully created your project</p>
          </div>
          <div class="step-2">
            <span class="success"
            v-if="activeProject && activeProject.billing && activeProject.billing.is_verified"
            >
              <svg>
                <use v-bind="{ 'xlink:href': '#tick-icon-white' }" />
              </svg>
            </span>
            <span v-else class="error">
              <svg>
                <use v-bind="{ 'xlink:href': '#arrow-icon' }" />
              </svg>
            </span>
            <div>
              <template v-if="activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium === 'invoice'
                && !activeProject.project_license.license_key.isPackage">
                <p class="error">Your billing profile is under review</p>
                <p>
                  Your billing profile is under verification. This shouldn't take long, however
                  if you are not willing to wait, you can always go ahead and change your payment method.
                </p>
              </template>
              <template v-else-if="((activeProject.billing &&
                (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium !== 'invoice')
                || (!activeProject.billing || activeProject.billing === null))
                && !activeProject.project_license.license_key.isPackage">
                  <template v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                  && !activeProject.project_license.license_key.isPremiumAnnual">
                    <p class="error">Please set up a billing profile</p>
                    <p>
                      Please set up billing profile to access live sports data
                    </p>
                  </template>
                  <template v-else>
                    <p class="error">Complete your Payment</p>
                    <p>
                      Complete your Pending Payment for Annual Plan
                    </p>
                  </template>
              </template>
              <template v-else-if="((activeProject.billing &&
                (activeProject.billing && !activeProject.billing.is_verified))
                || (!activeProject.billing || activeProject.billing === null))
                && activeProject.project_license.license_key.isPackage">
                <p class="error">Payment Pending for your plan</p>
                <p class="package-content">
                  Your project is not activated. This may be because you have a pending due.
                  Please check the invoice in your <a href="billing">billing</a> section.
                </p>
              </template>
              <div class="button-wrapper">
                <template v-if="(activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                  && activeProject.billing.preferred_payment_medium !== 'invoice'
                  || (!activeProject.billing || activeProject.billing === null))
                  && !activeProject.project_license.license_key.isPackage">
                  <template v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                  && !activeProject.project_license.license_key.isPremiumAnnual">
                    <a
                      :href="link+'user/project/'+activeProject.key+'/wizard?step=billing'"
                      class="btn-style-common medium"
                      target="_blank"
                    >Set up Billing Profile</a>
                  </template>
                  <template v-else>
                    <a
                      :href="
                        link+'user/project/'+activeProject.key+'/wizard?step=payment&prepay='
                        +activeProject.project_license.product+'|50'"
                      class="btn-style-common medium"
                      target="_blank"
                    >Pay</a>
                  </template>
                </template>
                <template v-if="activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                  && activeProject.billing.preferred_payment_medium === 'invoice'
                  && !activeProject.project_license.license_key.isPackage">
                  <a
                    v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                    && !activeProject.project_license.license_key.isPremiumAnnual"
                    :href="link+'user/project/'+activeProject.key+'/wizard?step=payment'"
                    class="btn-style-common medium"
                    target="_blank"
                  >Change payment Method</a>
                  <a
                    v-else
                    :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&prepay='
                      +activeProject.project_license.product+'|50'"
                    class="btn-style-common medium"
                    target="_blank"
                  >Change payment Method</a>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div class="step-3" v-if="activeProject && activeProject.billing && activeProject.billing.is_verified">
          <div>
            <h3>Get Started</h3>
            <p>Only authenticated users can access data from the Cricket API server.
               The Authentication endpoint provides the access token required for retrieving data using
               every other API. The token expires in 24 hours.
            </p>
            <a :href="authRestApiLink" class="btn-style-common medium">Get More Info</a>
          </div>
        </div>
      </div>
      <div :class="{'pending-payment-section' : isPendingPayment}">
        <div v-if="isPendingPayment &&
          activeProject.project_license.license_key.isPackage">
          <p class="error-blk">
            Payment is still pending for certain plans in your Project.
          </p>
          <p class="package-content">
            Please check the invoice in your <a href="billing">billing</a> section.
          </p>
        </div>
        <div v-if="isPendingPayment && invoiceDetails"
          class="billing-details">
          <p v-if="invoiceDetails?.bank_name">
            <label>Bank Name :</label>
            {{ invoiceDetails?.bank_name }}
          </p>
            <p v-if="invoiceDetails?.account_number">
            <label>Account Number :</label>
            {{invoiceDetails?.account_number}}
          </p>
          <p v-if="invoiceDetails?.ifsc_code">
            <label>IFSC Code:</label>
            {{invoiceDetails?.ifsc_code}}
          </p>
          <p v-if="invoiceDetails?.beneficiary_name" >
            <label>Beneficiary Name:</label>
              {{ invoiceDetails?.beneficiary_name }}
          </p>
        </div>
        </div>
      <div class="getting-started__email-verification"
      v-if="!activeUser.email_verified"
      :class="activeUser.email_verified ? 'border-line': null">
        <div class="getting-started__email-verification--left-col">
          <h3>
            Verify your email address
          </h3>
          <p>
            Send a verification email to confirm your email address.
          </p>
        </div>
        <div class="getting-started__email-verification--right-col">
          <button v-if="!emailSent" @click="verifyEmail">
            Resend</button>
          <button v-else-if="emailSent" class="sent-btn">
            <svg>
              <use v-bind="{'xlink:href':'#mail-sent-icon'}" />
            </svg>
            Email Sent
          </button>
        </div>
      </div>
      <div class="packages-credentials-section">
        <div class="packages-section" v-if="isPackage">
          <PackageList
            :activeProject="activeProject"
            :linechartData="linechartData"
            :apihitslabel="apihitslabel"
            :emptyState="emptyState"
            :linechart="linechart"
            :link="link"
           />
        </div>
        <div
          class="getting-started__project-credentials"
          :class="{'flex': !isPackage}"
          v-if="activeProject.hasValidLicense">
          <div class="credentials-section">
            <Credentials v-if="activeProject.hasValidLicense || keyData" class="credentials"
            :tournament="(keyData && keyData.tournament_keys)" :association="(keyData
            && keyData.association_keys)"
          />
          </div>
          <!-- <div class="hits-and-match-counts-wrapper" v-if="activeProject.project_license.license_key.isPackage">
            <div class="match-wrapper">
              <h4>Matches available</h4>
              <p>
                {{activeProject.available_usage.matches}}
              </p>
              <span class="label">of
                {{activeProject.available_usage.matches + activeProject.used_usage.matches}} matches</span>
              <a target="_blank" :class="[activeProject.available_usage.matches === 0
                ? 'error btn-style-common' : '', 'btn-style-common outlined']"
                title="Add more matches" :href="paymentLink">
                Add more matches</a>
            </div>
            <div class="api-hits-wrapper">
              <h4>Remaining API hits</h4>
              <p>{{activeProject.available_usage.hits}}</p>
              <span class="label">of
                {{activeProject.available_usage.hits + activeProject.used_usage.hits}} hits</span>
              <a target="_blank"
                :href="paymentLink" title="buy more hits"
                :class="[activeProject.available_usage.hits === 0 ? 'btn-style-common error' : '', 'btn-style-common']"
              >
                Buy more hits
              </a>
            </div>
          </div> -->
          <div class="content-section">
            <a :href="pricingLink">
              <img src="@/assets/virtual_sports.png" />
              <h3>Plans and Packages</h3>
              <p>Your one-stop-shop for all cricket matches.</p>
              <a :href="pricingLink" class="explore-more">Explore Pricing</a>
            </a>
            <a :href="docsLink">
              <img src="@/assets/docs.png" />
              <h3>Docs</h3>
              <p>You don't need it. You may not read it. All our API's and response are self-explanatory.</p>
              <a :href="docsLink" class="explore-more">Read more</a>
            </a>
          </div>
      </div>
      </div>
      <div class="priority-support-wrapper" v-if="!isPackage">
        <PrioritySupport
          class="dashboard__priority-support"
          v-if="activeProject.show_get_started"
        />
        <div class="committed-usage-block"
        :class="[activeProject.project_license.license_key.isEnterprise ? 'enterprise' : 'standard']">
          <h3>Introducing GraphQL for Cricket</h3>
          <p>Achieve all that is needed simply in just a single API request.</p>
          <a target="_blank"
            :href="graphqlLink" title="Committed use"
            class="btn-style-common medium"
          >
            Explore
          </a>
        </div>
      </div>
      <template v-if="!isPackage">
        <div class="usage-info">
          <h2 class="usage-info__header">
            <svg>
              <use v-bind="{ 'xlink:href': '#usage-icon'}" />
            </svg>
            API Usage
          </h2>
          <router-link
            :to="{ name: 'usageReport', params: { projectKey: activeProject.key }}" class="usage-info__desc--link">
            Explore Usage
          </router-link>
        </div>
        <div class="dashboard__graph-section--daywise-graph"
          v-if="linechartData && activeProject.usage && activeProject.hasValidLicense">
          <div class="daily-api-usage__chart-wrapper">
            <div class="daily-usage__amount-wrapper">
              <h4>Total Spent</h4>
              <span>
                {{formatCurrency(activeProject.usage.total_api_spending, activeProject.usage.currency, false, true)}}
              </span>
            </div>
            <div class="chart-div" v-if="activeProject && activeProject.usage
              && activeProject.usage.total_api_spending > 1">
              <chart :chartType="'line'"
              :dataArray="linechartContent" :dataSets="chartDataSets"
              :labels="linelabelData" :borderColor="chart.borderColor"
              :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
            </div>
            <div class="chart-div" v-else-if="activeProject && activeProject.usage
              && activeProject.usage.total_api_spending <= 0">
              <chart :chartType="'line'"
              :options="emptyState.options"
              :dataArray="linechartContent" :dataSets="chartDataSets"
              :labels="linelabelData" :borderColor="chart.borderColor"
              :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
              <div class="empty-state-graph">
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard__graph-section--daywise-graph"
          v-else-if="activeProject && activeProject.usage && !activeProject.usage.total_api_spending">
          <div class="daily-api-usage__chart-wrapper">
            <div class="daily-usage__amount-wrapper">
              <h4>Total Spent</h4>
              <span>0</span>
            </div>
            <div class="chart-div">
              <chart :chartType="'line'"
              :options="emptyState.options"
              :dataArray="linechartContent" :dataSets="chartDataSets"
              :labels="linelabelData" :borderColor="chart.borderColor"
              :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
              <div class="empty-state-graph">
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="loader" v-else>
          <div class="skeleton-box"></div>
        </div>
      </template>
    </div>
    <div class="next-step"
     v-else-if="!activeProject.show_get_started">
      <div class="align-left">
        <div class="pending-bills"
          v-if="activeProject && activeProject.unpaidBillInfo &&
            activeProject.unpaidBillInfo.invoices && activeProject.unpaidBillInfo.invoices.length > 0"
        >
          <h3>Pending Bills</h3>
          <div :class="[activeProject && activeProject.unpaidBillInfo &&
            activeProject.unpaidBillInfo.invoices && activeProject.unpaidBillInfo.invoices.length === 0
            ? 'hide' : '']"
            v-if="activeProject.loaded && activeProject.unpaidBillInfo
            && activeProject.unpaidBillInfo.invoices.length > 0">
            <div class="wrapper">
              <div v-for="info in activeProject.unpaidBillInfo.invoices" :key="info.key">
                <div class="content-wrapper">
                  <p class="amount">{{formatCurrency(info.grand_total, info.currency)}}</p>
                  <p class="status">Pending</p>
                </div>
                <div class="content-wrapper">
                  <p class="period">Period</p>
                  <p>{{$filters.formatMonth(info.generated_date)}}</p>
                </div>
                <router-link
                  class="view-bill-btn"
                  :to="{ name: 'invoice', params: { invoicekey: info.key, } }">
                  View Bill
                </router-link>
                <a
                  class="btn-style-common medium"
                  :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&invoice='
                  +info.key"
                  target="_blank">Pay</a>
              </div>
            </div>
          </div>
        </div>
        <template v-if="!isPackage">
          <div class="usage-info">
            <h2 class="usage-info__header">
              <svg>
                <use v-bind="{ 'xlink:href': '#usage-icon'}" />
              </svg>
              API Usage
            </h2>
            <router-link
              :to="{ name: 'usageReport', params: { projectKey: activeProject.key }}" class="usage-info__desc--link">
              Explore Usage
            </router-link>
          </div>
          <div class="dashboard__graph-section--daywise-graph"
            v-if="linechartData && activeProject.usage && activeProject.hasValidLicense">
            <div class="daily-api-usage__chart-wrapper">
              <div class="daily-usage__amount-wrapper">
                <h4>Total Spent</h4>
                <span>
                  {{formatCurrency(activeProject.usage.total_api_spending, activeProject.usage.currency, false, true)}}
                </span>
              </div>
              <div class="chart-div" v-if="activeProject && activeProject.usage
                && activeProject.usage.total_api_spending > 1">
                <chart :chartType="'line'"
                :dataArray="linechartContent" :dataSets="chartDataSets"
                :labels="linelabelData" :borderColor="chart.borderColor"
                :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
              </div>
              <div class="chart-div" v-else-if="activeProject && activeProject.usage
                && activeProject.usage.total_api_spending <= 0">
                <chart :chartType="'line'"
                :options="emptyState.options"
                :dataArray="linechartContent" :dataSets="chartDataSets"
                :labels="linelabelData" :borderColor="chart.borderColor"
                :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
                <div class="empty-state-graph">
                  <hr>
                </div>
              </div>
            </div>
          </div>
          <div class="loader chart-loader" v-else>
            <div class="skeleton-box"></div>
          </div>
          <PrioritySupport
            :layout-type="'dual-column'"
            class="dashboard__priority-support"
          />
          <div class="committed-usage-block"
          :class="[activeProject.project_license.license_key.isEnterprise ? 'enterprise' : 'standard']">
            <h3>Introducing GraphQL for Cricket</h3>
            <p>Achieve all that is needed simply in just a single API request.</p>
            <a target="_blank"
              :href="graphqlLink" title="Committed use"
              class="btn-style-common medium"
            >
              Explore
            </a>
          </div>
        </template>
       <div v-else-if="isPackage">
        <div class="packages-section">
          <PackageList
            :activeProject="activeProject"
            :linechartData="linechartData"
            :apihitslabel="apihitslabel"
            :emptyState="emptyState"
            :linechart="linechart"
            :link="link"
           />
        </div>
       </div>
        <div class="recent-invoices" v-if="activeProject
          &&  activeProject.billingInfo
          && activeProject.billingInfo.invoices.length >= 1"
          :class="[activeProject.project_license.license_key.isPackage ? 'add-padding': '']"
        >
          <h2>
            <svg>
              <use v-bind="{ 'xlink:href': '#invoice-icon'}" />
            </svg>
            Recent Invoices
          </h2>
          <router-link
            :to="{ name: 'billing', params: { projectKey: activeProject.key }}" class="see-more-btn">
            See more
          </router-link>
          <div>
            <table class="bill">
              <tbody v-if="activeProject && activeProject.billingInfo.invoices.length">
                <tr class="bill"
                  v-for="(invoice, index) in activeProject.billingInfo.invoices"
                  :key="index"
                >
                  <template v-if="index < 5">
                    <td>
                      <router-link
                        class="bill__date"
                        :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                      >
                        {{dateConversion(invoice.generated_date, 'MMM D, YYYY')}}
                      </router-link>
                    </td>
                    <td>
                      <router-link
                        class="bill__bill-number"
                        :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                      >
                        {{invoice.key}}
                      </router-link>
                    </td>
                    <td>
                      <router-link
                        class="bill__amount"
                        :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                      >
                        {{formatCurrency(invoice.grand_total, invoice.currency)}}
                      </router-link>
                    </td>
                    <td class="payment-status">
                      <router-link
                        :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                      >
                        <span class="paid" v-if="invoice.is_fulfilled && !invoice.status.isCancelledInvoice">Paid</span>
                        <span class="unpaid" v-else-if="invoice.status.isCancelledInvoice">Invoice Cancelled</span>
                        <span class="unpaid" v-else>Unpaid</span>
                      </router-link>
                    </td>
                  </template>
                </tr>
              </tbody>
              <tbody class="empty-state" v-else>
                <tr>
                  <td colspan="4">
                    <img src="@/assets/chessboard.svg" class="empty-state__image" alt="chess board with two coins"/>
                    <p class="empty-state__text"> No Bill Generated </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="recent-transactions" v-if="activeProject && activeProject.transactionInfo
          && activeProject.transactionInfo.transactions.length >= 1">
          <h2>
            <svg>
              <use v-bind="{ 'xlink:href': '#transaction-icon'}" />
            </svg>
            Recent Transactions
          </h2>
          <router-link
            :to="{ name: 'billing', params: { projectKey: activeProject.key }}" class="see-more-btn">
            See more
          </router-link>
          <table>
            <tbody v-if="activeProject && activeProject.transactionInfo
                && activeProject.transactionInfo.transactions">
              <template  v-for="(transaction, index) in (activeProject && activeProject.transactionInfo
                && activeProject.transactionInfo.transactions)" :key="index">
                <tr :class="[transaction.notes ? 'table-row' : '']" v-if="index < 5">
                  <td>
                    <router-link
                      class="bill__date"
                      :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                    >
                      {{$filters.format(transaction.created_at)}}
                    </router-link>
                  </td>
                  <td>
                    <router-link
                      class="bill__bill-number"
                      :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                    >
                      {{transaction.key}}
                    </router-link>
                  </td>
                  <td>
                    <router-link
                      class="bill__amount"
                      :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                    >
                      {{ formatCurrency(transaction.amount, transaction.currency)}}
                      <span v-if="transaction.kind !== 'money'">
                        <svg>
                          <use v-bind="{ 'xlink:href': '#virtual-money-icon' }" />
                        </svg>
                      </span>
                    </router-link>
                  </td>
                  <td class="payment-status">
                    <router-link
                      :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                    >
                      <span v-if="transaction.status.isWaitingForPayment ||
                      transaction.status.isPaymentFailed" class="unpaid">
                        Unsuccessful
                      </span>
                      <span v-else class="paid">
                        Successful
                      </span>
                    </router-link>
                  </td>
                </tr>
                <tr class="notes-row" v-if="transaction.notes && index < 5">
                  <td colspan="4">
                    <p v-if="transaction.notes">
                      <svg>
                        <use v-bind="{ 'xlink:href': '#notes-yellow' }" />
                      </svg> {{transaction.notes}}</p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody class="empty-state" v-else>
              <tr>
                <td colspan="4">
                  <img src="@/assets/chessboard.svg" class="empty-state__image" alt="chess board with two coins"/>
                  <p class="empty-state__text"> No Bill Generated </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="align-right">
        <div class="credentials-section">
          <Credentials v-if="activeProject.hasValidLicense || keyData" class="credentials"
            :tournament="(keyData && keyData.tournament_keys)"
            :association="(keyData && keyData.association_keys)"
          />
        </div>
        <div class="content-section">
          <div>
            <img src="@/assets/virtual-sports1.png" />
            <h3>Plans and Packages</h3>
            <p>Your one-stop-shop for all cricket matches.</p>
            <a :href="pricingLink" class="explore-more">Explore Pricing</a>
          </div>
          <div>
            <img src="@/assets/docs-2.png" />
            <h3>Docs</h3>
            <p>You don't need it. You may not read it. All our API's and response are self-explanatory.</p>
            <a :href="docsLink" class="explore-more">Read more</a>
          </div>
        </div>
      </div>
    </div>

    <div class="loader" v-else>
      <div class="skeleton-box"></div>
    </div>
    <div v-if="activeProject && activeUser?.groups?.length > 0
      && activeProject.created_by && activeProject.project_license.license_key.isPackage"
      class="admin-actions-block"
    >
      <div class="title-block">
        <svg>
          <use v-bind="{ 'xlink:href': '#settings-icon'}" />
        </svg>
        <h3>Admin Actions</h3>
      </div>
      <ExtendPlanAndAttachTrail :action="'extend'" :activeProject="activeProject" />
      <ExtendPlanAndAttachTrail :action="'attach'" :activeProject="activeProject" />
    </div>
  </div>
</template>

<style lang="scss">
.dashboard {
  .chart-div {
    position: relative;
    margin-bottom: size(30);
    canvas {
      max-height: size(430);
    }
  }
  .console-home__header {
    margin-bottom: size(30);
  }
  .hits-and-match-counts-wrapper {
    background: url('../assets/usage-img.svg') no-repeat 88%;
    background-size: size(44);
    background-color: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    background-position: top size(20) right size(20);
    .match-wrapper {
      margin: 0 size(20) size(20);
      padding: size(20) 0;
      border-bottom: size(1) solid var(--global--border--Color);
    }
    a {
      margin-top: size(20);
    }
    h4 {
      @include font-styles(
        $type: 'label',
        $size: 'sm',
        $weight: 'normal'
      );
      margin-bottom: size(4);
      color: var(--global--main--title--Color);
    }
    .label {
      @include font-styles(
        $type: 'label',
        $size: 'sm',
        $weight: 'normal'
      );
      display: block;
      padding-top: size(10);
      color: var(--global--gray--Color);
    }
    p {
      margin-top: size(10);
      @include font-styles(
        $type: 'display',
        $size: 'xs',
        $weight: 'bold'
      );
      color: var(--global--main-title--Color);
    }
    .error {
      background: #be3146;
      border: size(1) solid #be3146;
      color: var(--global--white--Color);
    }
    .api-hits-wrapper {
      background: url('../assets/cash-balance-bg.png') no-repeat 100%;
      background-size: size(130);
      background-position: bottom right;
      padding: 0 size(20) size(20);
    }
  }
  .empty-chart {
    position: absolute;
    bottom: size(25);
    padding: size(250) 0 0;
    left: 0;
    right: 0;
    background: var(--global--white--Color);
    canvas {
      height: size(50)!important;
    }
  }
  .empty-state-graph {
    padding: size(200) 0;
    background: var(--global--white--Color);
    top: size(-30);
    right: 0;
    position: absolute;
    left: 0;
    hr {
      height: size(3);
      background: #0085FF;
    }
    &.api-hits {
      hr {
        background: #0085FF;
      }
    }
  }
  .next-step {
    .empty-state-graph {
      padding: size(140) 0;
      background: var(--global--white--Color);
      top: size(-30);
      right: 0;
      position: absolute;
      left: 0;
      hr {
        height: size(3);
        background: #0085FF;
      }
      &.api-hits {
        hr {
          background: #0085FF;
        }
      }
    }
  }
  &__billing-section {
    .api-usage {
      background: var(--global--white--Color);
    }
  }
  .getting-started {
    &__project-credentials {
      .credentials {
        padding: size(0);
        &__header {
          display: none;
        }
        .key-box {
          margin-bottom: size(20);
          &__label {
          padding-bottom: size(10);
          }
        }
      }
    }
  }
  .daily-api-usage__chart-wrapper {
    .widget-box {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      .chart-area-wrapper {
        width: 100%;
        overflow-x: auto;
        canvas {
          height: size(300);
        }
      }
    }
  }
}
.admin-actions-block {
  .preview-box {
    margin-bottom: size(10);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.dashboard {
  & .usage-info {
    margin-bottom: size(18);
    &__header {
      display: inline-block;
      @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
      color: var(--global--main--title--Color);
      svg {
        fill: var(--global--usage-img--Color);
        width: size(19);
        height: size(19);
        display: inline-block;
        vertical-align: middle;
        margin-right: size(15);
      }
    }
    a {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      color: var(--global--common-link--Color);
      float: right;
    }
  }
  .recent-invoices {
    &.add-padding {
      margin-top: size(30);
    }
  }
  .recent-invoices, .recent-transactions {
    &:after {
      content: "";
      clear: both;
      display: block;
    }
    margin-bottom: size(35);
    svg {
      display: inline-block;
      vertical-align: middle;
      width: size(19);
      height: size(19);
      stroke:  #12A646;
      fill: #12A646;
      margin-right: size(10);
    }
    h2 {
      @include font-styles(
        $type: 'text',
        $size: 'lg',
        $weight: 'bold'
      );
      padding-bottom: size(16);
      display: inline-block;
    }
    .see-more-btn {
      @include font-styles(
        $type: 'label',
        $size: 'lg',
        $weight: 'normal'
      );
      float: right;
      color: var(--global--common-link--Color);
    }
  }
  .recent-transactions {
    h2 {
      svg {
        fill: #FF9900;
        stroke: #FF9900;
      }
    }
  }
  table{
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    border-collapse: separate;
    padding: 0 size(20) size(20);
    width: 100%;
    text-align: left;
    tbody {
      tr {
        td {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'normal'
          );
          color: var(--global--gray--Color);
          padding: size(20);
          border-bottom: size(1) solid var(--global--border--Color);
          &:first-child {
            padding-left: 0;
            padding-right: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          a {
            color: var(--global--gray--Color);
          }
        }
        &:last-child {
          td {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        &.table-row {
          td {
            padding: size(20) size(20) 0;
            border: none;
            &:first-child {
              padding: size(20) 0 0;
            }
            &:last-child {
              padding: size(20) size(20) 0;
              width: auto;
            }
          }
        }
        &.notes-row {
          td {
            border-bottom: size(1) solid var(--global--border--Color);
            padding: size(10) 0 size(20);
            p {
              background: #FFFFC7;
              border-radius: size(6);
              padding: size(10);
              @include font-styles(
                $type: 'label',
                $size: 'sm',
                $weight: 'regular'
              );
            }
            svg {
              fill: #F0F06D;
              stroke: #F0F06D;
            }
          }
          &:last-child {
            td {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
        .bill__date {
          color: var(--global--common-link--Color);
        }
        .bill__amount {
          color: #111;
          svg {
            width: size(25);
            height: size(25);
            display: inline-block;
            vertical-align: text-bottom;
            fill: var(--global--virtual-icon--BackgroundColor);
            stroke: var(--global--white--Color);
          }
        }
        .payment-status {
          cursor: pointer;
          @include font-styles(
            $type: 'label',
            $size: 'sm',
            $weight: 'normal'
          );
          span {
            display: inline-block;
            padding: size(2) size(10);
            border-radius: size(6);
          }
          .paid {
            color:  #00A656;
            background: rgba(0, 166, 86, 0.1);
          }
          .unpaid {
            color: #C83739;
            background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
          }
        }
      }
    }
    .transaction-body {
      tr {
        &:hover {
          cursor: pointer;
          .transaction__icon-wrapper {
            display: inline-block;
            vertical-align: top;
            svg {
              fill: var(--global--common-link--Color);
            }
          }
          td{
            color: var(--global--common-link--Color);
            .unpaid, .paid {
              span {
                color: var(--global--common-link--Color);
              }
            }
          }
        }
        td {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'normal'
          );
          color: var(--global--gray--Color);
          padding: size(20);
          border-bottom: size(1) solid var(--global--line--table--BackgroundColor);
          &:first-child {
            padding-left: 0;
            width: size(125);
          }
          &:last-child {
            padding: size(20) 0;
          }
          span {
            display: inline-block;
            vertical-align: middle;
            padding: size(2) size(10);
            border-radius: size(6);
            @include font-styles(
              $type: 'label',
              $size: 'sm',
              $weight: 'normal'
            );
          }
          p {
            @include font-styles(
              $type: 'label',
              $size: 'sm',
              $weight: 'normal'
            );
            color: var(--global--main--title--Color);
            padding-top: size(5);
          }
          .successful {
            color:  #00A656;
            background: rgba(0, 166, 86, 0.1);
          }
          .unsuccessful {
            color: #C83739;
            background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
          }
        }
        .transaction__date{
          color: var(--global--common-link--Color);
        }
        .transaction__amount {
          color: #111;
          svg {
            width: size(25);
            height: size(25);
            display: inline-block;
            vertical-align: text-bottom;
            fill: var(--global--virtual-icon--BackgroundColor);
            stroke: var(--global--white--Color);
          }
        }
        .transaction__icon-wrapper {
          display: none;
          a {
            display: inline-block;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
          }
          &> svg {
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
            &:last-child  {
              margin-right: 0;
            }
          }
          svg {
            cursor: pointer;
            &.view-icon {
              width: size(17);
              height: size(12);
            }
            &.mail-icon {
              width: size(16);
              height: size(12);
            }
            &.download-icon {
              width: size(12);
              height: size(12);
            }
          }
        }
      }
    }
  }
  .committed-usage-block {
    display: none;
    background: url('../assets/cash-balance-bg.png') no-repeat 100%;
    background-size: size(130);
    background-position: bottom right;
    padding: size(30) size(20);
    box-shadow: 0 size(1) size(3)
      rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
      0 size(1) size(1) rgba(0, 0, 0, 0.08);
    background-color: var(--global--white--Color);
    border-radius: size(8);
    &.standard {
      padding: size(38) size(20);
    }
    &.enterprise {
      padding: size(30) size(20);
      width: 100%;
    }
    .btn-style-common {
      background: #be3146;
      border: size(1) solid #be3146;
    }
    h3 {
      span {
        color: #5141BD;
        @include font-styles(
          $type: 'text',
          $size: 'md',
          $weight: 'bold'
        );
      }
      @include font-styles(
        $type: 'text',
        $size: 'md',
        $weight: 'bold'
      );
      color: var(--global--main--title--Color);
      padding-bottom: size(10);
    }
    p {
      @include font-styles(
        $type: 'label',
        $size: 'md',
        $weight: 'regular'
      );
      color: var(--global--heading--Color);
      padding-bottom: size(20);
    }
  }
  .getting-started {
    margin-bottom: size(50);
    min-width: size(1040);
    .billing-verified-promo, .keyData-promo {
      padding: size(12) size(20);
      border-radius: size(8);
      background: #97E6AB;
      margin-bottom: size(15);
      svg {
        display: inline-block;
        vertical-align: middle;
        width: size(12);
        height: size(9);
      }
      span {
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        text-align: center;
        margin-right: size(10);
        width: size(22);
        height: size(22);
        color: var(--global--white--Color);
        @include font-styles($type: 'label', $size: 'sm', $weight: 'normal');
        &.success {
          background: #00A656;
          padding: size(3) 0;
        }
      }
      p {
        display: inline-block;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      }
    }
    .keyData-promo {
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3)
       rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
       0 size(1) size(1) rgba(0, 0, 0, 0.08);
      span {
        display: inline-block;
        vertical-align: revert;
        width: auto;
        height: auto;
        font-size: size(15);
        line-height: size(17);
        font-weight: 600;
        color: var(--global--primary--Color);
        margin-left: size(4);
      }
    }
    .priority-support-wrapper {
      display: flex;
      padding: size(50) 0;
      .priority-support {
        margin-right: size(30);
        &:empty {
          margin: 0;
        }
      }
    }
    .free-trail-promo {
      padding: size(15);
      text-align: center;
      border-radius: size(8);
      background: #FFC220;
      margin-bottom: size(15);
      p {
        display: inline-block;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      }
      a {
        text-decoration: underline;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'semibold');
        color: var(--global--main--Color);
      }
    }
    &_steps {
      box-shadow: 0 size(1) size(3)
       rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
       0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      background: var(--global--white--Color);
      padding: size(30) size(20);
      div {
        p {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
          padding-bottom: size(16);
          margin-bottom: size(16);
          border-bottom: size(1) solid #DADADA;
          color: var(--global--primary--Color);
          display: inline-block;
          width: calc(100% - #{size(52)});
        }
        &.step-2 {
          p {
            border-bottom: none;
            &.error {
              color: #C83739;
              font-weight: bold;
            }
            &.package-content {
              padding-bottom: 0;
              a {
                text-decoration: underline;
              }
            }
          }
          .button-wrapper {
            .btn-style-common {
              margin-right: size(10);
            }
          }
        }
        & > div {
          display: inline-block;
          width: calc(100% - #{size(52)});
          p {
            margin: 0;
            padding-bottom: size(10);
          }
        }
        &:last-child {
          p {
            border-bottom: none;
          }
        }
        svg {
          display: inline-block;
          vertical-align: middle;
          width: size(12);
          height: size(9);
        }
        h3 {
          @include font-styles($type: 'text', $size: 'md', $weight: 'bold');
          padding-bottom: size(10);
        }
        span {
          display: inline-block;
          vertical-align: top;
          border-radius: 50%;
          text-align: center;
          margin-right: size(30);
          width: size(22);
          height: size(22);
          color: var(--global--white--Color);
          @include font-styles($type: 'label', $size: 'sm', $weight: 'normal');
          &.success {
            background: #00A656;
            padding: size(3) 0;
          }
          &.error {
            background: #C83739;
            padding: size(3) 0;
            svg {
              stroke: var(--global--white--Color);
              fill: none;
            }
          }
        }
      }
    }
    .pending-payment-section {
      margin: size(30) size(0);
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      padding:  size(30)  size(15) size(15);
      .error-blk {
        color: #C83739;
        font-weight: bold;
      }

      .package-content {
        margin-top: size(16);
      }
    }
    .billing-details {

      margin-top: size(30);
      p {
        margin-bottom: size(12);
        padding: 0;
      }
      label {
        @include font-styles($type: 'text', $size: 'sm', $weight: 'semibold');
      }
  }
    .sent-btn {
      color: var(--global--success--BackgroundColor);
      cursor: pointer;
      @include font-styles($type: 'label', $size: 'sm', $weight: 'regular');
      svg {
        display: inline-block;
        position: static;
        transform: none;
        width: size(15);
        height: size(15);
        margin-left: size(8);
        fill: var(--global--success--BackgroundColor);
      }
    }
    &__header {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
      margin-bottom: size(14);
      color: var(--global--main--title--Color);
    }
    &__email-verification {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
      margin-top: size(14);
      border: size(1) solid #DADADA;
      border-radius: size(8);
      padding: size(20);
      display: flex;
      justify-content: space-between;
      &--left-col {
        flex: 10;
        h3 {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
          padding-bottom: size(6);
        }
        p {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
          margin-bottom: 0;
        }
      }
      &--right-col {
        flex: 1;
        text-align: right;
        button {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
          color: var(--global--common-link--Color);
          cursor: pointer;
        }
      }
      svg {
        position: absolute;
        left: size(-30);
        top: 50%;
        transform: translateY(-50%);
        height: size(12);
        width: size(12);
        fill: var(--global--common-link--Color);
      }
    }
    .packages-credentials-section {
      display: flex;
      gap: size(50);
      margin-top: size(50);

      .packages-section {
        flex: 0.7;
      }
    }
    &__project-credentials {
        flex: 0.3;

        .content-section {
          margin: size(20) 0 size(30) 0;
          a {
            display: block;
            width: 100%;
            color: var(--global--primary--Color);

            .explore-more {
              width: max-content;
              margin-top: size(20);
            }

            &:not(:last-child) {
              margin-bottom: size(24);
            }
            img {
              width: 100%;
            }
            h3 {
              @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
              padding: size(8) 0;
            }
            p {
              @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
            }
            & > a {
              @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
              color: var(--global--primary--Color);
              width: auto;
              border-bottom: size(1) solid var(--global--primary--Color);
            }
          }
        }

        &.flex {
          flex: 1;
          display: flex;
          justify-content: space-between;

          a {
            width: size(280);
            display: inline-block;
            vertical-align: top;
            &:last-child {
              margin-left: size(30);
            }

            .explore-more {
              margin: size(8) 0 0 0;
            }
          }

          img {
            width: size(280)
          }
        }
    }
    .info {
      @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
      margin-bottom: size(10);
      color: var(--global--main--title--Color);
      .email-info {
        @include font-styles(
          $type: 'display',
          $size: 'xxs',
          $weight: 'semibold'
        );
        color: var(--global--common-link--Color);
        &.locked {
          @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
          color: var(--global--heading--Color);
        }
      }
      &.locked {
        color: var(--global--heading--Color);
        svg {
          height: size(12);
          width: size(12);
          fill: var(--global--heading--Color);
        }
      }
    }
    .border-line {
      border: size(1) solid var(--global--line--table--BackgroundColor);
    }
  }
  .daily-api-usage__chart-wrapper {
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    padding-top: size(40);
    .daily-usage__amount-wrapper, .daily-usage__usage-wrapper {
      display: inline-block;
      margin-bottom: size(40);
      padding-left: size(40);
      h4 {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'regular'
        );
        padding-bottom: size(10);
      }
      span {
        @include font-styles(
          $type: 'text',
          $size: 'lg',
          $weight: 'bold'
        );
      }
    }
  }
  .resource-usage__chart-wrapper {
    padding-top: size(100);
    position: relative;
  }
  .loader {
    --loader--box--BackgroundColor: #0055bf33;

    background: var(--loader--box--BackgroundColor);
    width: 100%;
    animation: fadein .5s;
    border-radius: size(10);
    height: size(440);
    &.chart-loader {
      margin-bottom: size(30);
    }

    .skeleton-box {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: var(--loader--box--BackgroundColor);
      border-radius: size(10);

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#f8f6ff, 0) 0,
          rgba(#f8f6ff, 0.2) 20%,
          rgba(#f8f6ff, 0.5) 60%,
          rgba(#f8f6ff, 0)
        );
        animation: shimmer 1s infinite;
        content: '';
      }
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
  .next-step {
    display: flex;
    justify-content: space-between;
    margin-bottom: size(50);
    .align-left {
      width: size(610);
      .priority-support {
        &.dual-column {
          margin-bottom: size(36);
        }
      }
      .pending-bills {
        margin-bottom: size(30);
        .view-bill-btn {
          display: inline-block;
          color: var(--global--common-link--Color);
          padding: size(10) 0;
          margin-right: size(24);
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'regular'
          );
        }
        h3 {
          @include font-styles(
            $type: 'text',
            $size: 'lg',
            $weight: 'bold'
          );
          padding-bottom: size(14);
        }
        .wrapper {
          background: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
          0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          padding: 0 size(20);
          &> div {
            padding: size(20) 0;
            border-bottom: size(1) solid var(--global--border--Color);
            &:last-child {
              border-bottom: none;
            }
            .content-wrapper {
              display: inline-block;
              vertical-align: top;
              padding-right: size(135);
              @include font-styles(
                $type: 'label',
                $size: 'lg',
                $weight: 'normal'
              );
              &:first-child {
                padding-right: 0;
                margin-right: size(30);
                width: size(188);
              }
              .amount {
                @include font-styles(
                  $type: 'text',
                  $size: 'md',
                  $weight: 'bold'
                );
                padding-bottom: size(6);
              }
              .status {
                display: inline-block;
                padding: size(2) size(6);
                border-radius: size(6);
                color: #C83739;
                @include font-styles(
                  $type: 'label',
                  $size: 'sm',
                  $weight: 'normal'
                );
                background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
              }
              .period {
                @include font-styles(
                  $type: 'label',
                  $size: 'sm',
                  $weight: 'normal'
                );
                color: var(--global--gray--Color);
                padding-bottom: size(6);
              }
            }
          }
        }
      }
    }
    .committed-usage-block {
      margin-bottom: size(36);
      background-size: size(180);
      p {
        max-width: size(380);
      }
    }
    .align-right {
      width: size(380);
      .content-section {
        div {
          margin-top: size(30);
          width: size(380);
          img {
            width: size(380);
          }
          h3 {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
            padding: size(8) 0;
          }
          p {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
          }
          & > a {
            display: inline-block;
            @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
            color: var(--global--common-link--Color);
            margin: size(8) 0 0!important;
          }
        }
      }
    }
  }
}
.admin-actions-block {
   box-shadow: 0 size(1) size(3)
       rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
       0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      background: var(--global--white--Color);
      padding: size(30) size(20);

  .title-block {
    display: flex;
    align-items: center;
    gap: size(10);
    margin-bottom: size(20);

    svg {
      width: size(20);
      height: size(20);
    }

    h3 {
      font-size: size(16);
      line-height: size(22);
      font-weight: 500;
    }
  }

  .preview-box {
    margin-bottom: size(20);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Config from '@/config';
import { User } from '@/store/modules/user';
import PrioritySupport from '@/components/PrioritySupport.vue';
import Credentials from '@/components/Credentials.vue';
import PackageList from '@/components/Packages.vue';
import { currencyMethodsMixin } from '@/mixin/currency';
import { Project, supportNumbers } from '@/store/modules/project';
import ExtendPlanAndAttachTrail from '@/components/admin/ExtendPlanAndAttachTrial.vue';
import Chart from '../components/Charts/Chart.vue';

export default {
  name: 'Dashboard',
  mixins: [currencyMethodsMixin],
  components: {
    Credentials,
    PrioritySupport,
    Chart,
    ExtendPlanAndAttachTrail,
    PackageList,
  },
  data() {
    return {
      isPendingPayment: false,
      invoiceDetails: {},
      getStartedLink: `${Config.cricketApiHost}/get-started/`,
      consoleLink: Config.consoleHost,
      emailSent: false,
      usage: [],
      supportNumbers,
      chartInfo: {
        borderWidth: 0,
        options: { responsive: true, maintainAspectRatio: true },
      },
      chart: {
        borderWidth: 2,
        options: { responsive: true, maintainAspectRatio: true },
      },
      emptygraph: {
        options: {
          tooltips: {
            enabled: false,
          },
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                suggestedMin: 0,
              },
            }],
            yAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
            }],
          },
        },
      },
      resourceEmptyState: {
        labels: [
          'Resource 1',
          'Resource 2',
          'Resource 3',
          'Resource 4',
          'Resource 5',
          'Resource 6',
          'Resource 7',
          'Resource 8',
          'Resource 9',
          'Resource 10',
        ],
        data: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
      },
      emptyState: {
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 20,
              },
            }],
            yAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
            }],
          },
        },
      },
      linechart: {
        borderWidth: 2,
        options: { responsive: true, maintainAspectRatio: true },
      },
      maxLen: 10,
      dailyUsage: null,
      usageObj: [
        {
          className: 'api-usage',
          infoText: 'Day wise spending & API Usage',
        },
        { className: 'unbilled-usage', infoText: 'Resource Wise Spend' },
      ],
      barchartData: null,
      linechartData: null,
      linelabelData: null,
      linechartContent: null,
      barlabelData: null,
      barchartContent: null,
      activeMonth: null,
      isToolTipHidden: true,
      apichartData: null,
      apihitslabel: null,
      apihitsContent: null,
      link: Config.sportsHost,
      docsLink: `${Config.cricketApiHost}/docs/v5/cricket/docs/`,
      graphqlLink: `${Config.cricketApiHost}/v5/graphql/`,
      authRestApiLink: `${Config.cricketApiHost}/docs/v5/cricket/docs/auth-rest-api`,
      pricingLink: `${Config.cricketApiHost}/v5/package-pricing`,
      keyData: null,
      isPackage: false,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
      activeUser: (state) => state.user.activeUser,
    }),
  },

  watch: {
    activeProject(val, old) {
      if (old.key && (val.key !== old.key)) {
        this.fetchData();
      }
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {
    if (this.activeProject && this.activeProject.project_license
      && this.activeProject.project_license.license_key
      && this.activeProject.project_license.license_key.isPackage) {
      this.isPackage = true;
    }
  },

  methods: {
    fetchData() {
      if (this.activeProjectState.status.isLoaded) {
        this.fetchKeys(this.activeProject.key);
        this.fetchUsage();
        this.fetchInvoices();
        this.fetchTransactions();
        this.fetchUnpaidBills(this.activeProject.key);
      }
    },
    fetchUnpaidBills(key) {
      this.$store.dispatch('project/fetchUnpaidBillDetails', { key }).then((resp) => {
        console.log('got it', resp);
        this.showInvoiceDetails(resp?.invoices);
      }).catch((err) => {
        console.log('fetch usage error', err);
      });
    },
    fetchKeys(key) {
      Project.fetchPackageKeys(key).then((resp) => {
        this.keyData = resp;
      }).catch((err) => {
        console.log('fetch error', err);
      });
    },
    fetchInvoices(page) {
      this.isLoading = true;
      const params = {
        page: page || 1,
        projectKey: this.activeProject.key,
        limit: 3,
      };
      this.$store.dispatch('project/fetchInvoiceHistory', { params })
        .then(() => {
          console.log('got it');
          this.isLoading = false;
          const currentYear = new Date().getFullYear();
          let currentMonth = new Date().getMonth() + 1;
          if (currentMonth < 10) {
            currentMonth = `0${currentMonth}`;
          }
          this.activeMonth = `${currentYear}-${currentMonth}`;
          params.month = currentMonth;
          params.year = currentYear;
        }).catch(() => {
          console.log('error');
        });
    },
    fetchTransactions(_query) {
      if (this.activeProject.loaded) {
        const query = _query || {};
        query.key = this.activeProject.key;
        if (!query.page) {
          query.page = 1;
        }
        if (!query.params) {
          query.params = 'show_failed=1&show_waiting=1&show_successful=1';
          query.limit = 3;
        }
        this.$store.dispatch('project/fetchTransactionHistory', { query })
          .then(() => {
            if (query.params === 'show_successful=1') {
              this.showPaidTransaction = true;
              this.showUnpaidTransaction = false;
            } else {
              this.showPaidTransaction = false;
              this.showUnpaidTransaction = true;
            }
          });
      }
    },
    dateConversion(date, template) {
      return moment.unix(date).format(template);
    },
    fetchUsage() {
      const currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      this.activeMonth = `${currentYear}-${currentMonth}`;
      if (currentMonth < 10) {
        currentMonth = `0${currentMonth}`;
      }
      const params = {};
      params.month = currentMonth;
      params.year = currentYear;
      params.projectKey = this.activeProject.key;
      this.$store.dispatch('project/fetchProjectUsage', { params }).then(() => {
        this.prepareResourceUsage();
        this.prepareDaywiseUsage();
        this.prepareApiUsage();
      }).catch(() => {
        console.log('fetch usage error');
      });
    },

    prepareResourceUsage() {
      this.barchartData = this.activeProject && this.activeProject.usage
      && this.activeProject.usage.resource_wise_spending;
      this.barlabelData = [];
      this.barchartContent = [];
      if (this.barchartData) {
        Object.values(this.barchartData).forEach((val) => {
          let name = [];
          let content = [];
          name = val.name;
          content = val.total;
          this.barlabelData.push(name);
          this.barchartContent.push(content);
        });
      }
    },
    prepareDaywiseUsage() {
      this.linechartData = this.activeProject && this.activeProject.usage && this.activeProject.usage.day_wise_spending;
      if (this.linechartData) {
        this.linechartContent = Object.values(this.linechartData);
        const keyName = Object.keys(this.linechartData);
        for (let j = 0; j < keyName.length; j += 1) {
          keyName[j] = moment(keyName[j]).format('MMM DD');
        }
        this.linelabelData = keyName;
      }
    },

    prepareApiUsage() {
      this.apichartData = this.activeProject && this.activeProject.usage
        && this.activeProject.usage.day_wise_api_hits;
      if (this.apichartData) {
        this.apihitsContent = Object.values(this.apichartData);
        const key = Object.keys(this.apichartData);
        for (let i = 0; i < key.length; i += 1) {
          key[i] = moment(key[i]).format('MMM DD');
        }
        this.apihitslabel = key;
      }
    },

    verifyEmail() {
      return User.resendVerification('resend_verification_mail').then(() => {
        this.emailSent = true;
      });
    },

    showInvoiceDetails(invoices) {
      if (invoices && invoices?.length > 0) {
        const [details] = invoices;
        this.isPendingPayment = true;
        this.invoiceDetails = details?.invoice_payment_detail_raw;
      }
    },
  },
};
</script>
